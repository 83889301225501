.prv-modal-confirm[data-v-7cb437c2] {
  width: 91.3%;
  height: 80%;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  position: fixed;
  z-index: 999;
  background: rgba(0,0,0,0.35);
  -webkit-transition: 0.6s;
  transition: 0.6s;
  opacity: 0;
  visibility: hidden;
  position: absolute;
}
@supports ((-webkit-backdrop-filter: none) or (backdrop-filter: none)) {
.prv-modal-confirm[data-v-7cb437c2] {
    -webkit-backdrop-filter: blur(0.625rem);
            backdrop-filter: blur(0.625rem);
}
}
.prv-modal-confirm__dialog[data-v-7cb437c2] {
  max-width: 50vw;
  min-width: 33vw;
  background: #fff;
  z-index: 1000;
  border-radius: 0.1875rem;
  -webkit-box-shadow: 0 0.25rem 0.5rem 0 #d6d6d6;
          box-shadow: 0 0.25rem 0.5rem 0 #d6d6d6;
  opacity: 1;
  visibility: visible;
}
.prv-modal-confirm__close[data-v-7cb437c2] {
  color: #5a5959;
  position: absolute;
  top: 0;
  right: 0.5rem;
  font-size: 2.3rem;
  -webkit-transition: all 0.2s;
  transition: all 0.2s;
  line-height: 1;
  cursor: pointer;
}
.prv-modal-confirm__close[data-v-7cb437c2]:hover {
  color: #377bdc;
}
.prv-modal-confirm__show[data-v-7cb437c2] {
  opacity: 1;
  visibility: visible;
}
.prv-modal-confirm__show .prv-modal-confirm__dialog[data-v-7cb437c2] {
  -webkit-animation: bounceInDown-data-v-7cb437c2 1s both;
          animation: bounceInDown-data-v-7cb437c2 1s both;
}
.prv-modal-confirm__hidden[data-v-7cb437c2] {
  -webkit-transition: visibility 0s 1.25s, opacity 1.25s linear;
  transition: visibility 0s 1.25s, opacity 1.25s linear;
}
.prv-modal-confirm__hidden .prv-modal-confirm__dialog[data-v-7cb437c2] {
  -webkit-animation: bounceOutUp-data-v-7cb437c2 1s both;
          animation: bounceOutUp-data-v-7cb437c2 1s both;
}
.prv-modal-confirm__content[data-v-7cb437c2] {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  color: #5a5959;
  padding: 1.5rem;
}
.prv-modal-confirm__content h4[data-v-7cb437c2] {
  font-size: 1.4rem;
  font-weight: 800;
  margin: 1rem 0;
}
.prv-modal-confirm__content h2[data-v-7cb437c2] {
  font-size: 2rem;
  font-weight: 300;
  margin-top: 1rem;
}
.prv-modal-confirm__content p[data-v-7cb437c2] {
  font-size: 1.1rem;
  color: #5a5959;
  text-align: center;
  margin-top: 0.5rem;
}
.prv-modal-confirm__actions[data-v-7cb437c2] {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  padding: 1rem;
  gap: 3rem;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
}
.prv-modal-confirm__btn[data-v-7cb437c2] {
  text-decoration: none;
  font-family: Montserrat-SemiBold;
  padding: 0.5rem 3.5rem;
  border-color: transparent;
  border-radius: 0.2rem;
  font-size: 1rem;
  cursor: pointer;
  position: relative;
  -webkit-box-shadow: 0 0.25rem 0.5rem 0 #d6d6d6;
          box-shadow: 0 0.25rem 0.5rem 0 #d6d6d6;
  -webkit-appearance: button;
  max-width: 15rem;
}
.prv-modal-confirm__btn[data-v-7cb437c2]:disabled {
  cursor: default;
}
.prv-modal-confirm__btn[data-v-7cb437c2]::-moz-focus-inner {
  border-style: none;
  padding: 0;
}
.prv-modal-confirm__btn[data-v-7cb437c2]:-moz-focusring {
  outline: 0.0625rem dotted ButtonText;
}
.prv-modal-confirm__btn--blue[data-v-7cb437c2] {
  background-color: #377bdc;
  color: #fff;
}
.prv-modal-confirm__btn--blue[data-v-7cb437c2]:hover {
  background-color: #1b5fbf;
}
.prv-modal-confirm__btn--blue[data-v-7cb437c2]:disabled {
  background-color: #d7e4f5;
  color: #377bdc;
}
.prv-modal-confirm__btn--orange[data-v-7cb437c2] {
  background-color: #ea8279;
  color: #fff;
}
.prv-modal-confirm__btn--orange[data-v-7cb437c2]:hover {
  background-color: #c45e54;
}
.prv-modal-confirm__btn--orange[data-v-7cb437c2]:disabled {
  background-color: #eab5b1;
  color: #fff;
}
@-webkit-keyframes bounceInDown-data-v-7cb437c2 {
from, to {
    -webkit-animation-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1);
            animation-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1);
}
to {
    -webkit-transform: translate3d(0, 0, 0);
            transform: translate3d(0, 0, 0);
}
}
@keyframes bounceInDown-data-v-7cb437c2 {
from, 60%, 75%, 90%, to {
    -webkit-animation-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1);
            animation-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1);
}
0% {
    opacity: 0;
    -webkit-transform: translate3d(0, -187.5rem, 0);
            transform: translate3d(0, -187.5rem, 0);
}
60% {
    opacity: 1;
    -webkit-transform: translate3d(0, 1.5625rem, 0);
            transform: translate3d(0, 1.5625rem, 0);
}
75% {
    -webkit-transform: translate3d(0, -0.625rem, 0);
            transform: translate3d(0, -0.625rem, 0);
}
90% {
    -webkit-transform: translate3d(0, 0.3125rem, 0);
            transform: translate3d(0, 0.3125rem, 0);
}
to {
    -webkit-transform: translate3d(0, 0, 0);
            transform: translate3d(0, 0, 0);
}
}
@-webkit-keyframes bounceOutUp-data-v-7cb437c2 {
to {
    opacity: 0;
    -webkit-transform: translate3d(0, -125rem, 0);
            transform: translate3d(0, -125rem, 0);
}
}
@keyframes bounceOutUp-data-v-7cb437c2 {
20% {
    -webkit-transform: translate3d(0, -0.625rem, 0);
            transform: translate3d(0, -0.625rem, 0);
}
40%, 45% {
    opacity: 1;
    -webkit-transform: translate3d(0, 1.25rem, 0);
            transform: translate3d(0, 1.25rem, 0);
}
to {
    opacity: 0;
    -webkit-transform: translate3d(0, -125rem, 0);
            transform: translate3d(0, -125rem, 0);
}
}
