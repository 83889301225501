.prv-page-loader[data-v-c98f6bfc] {
  display: hidden;
  left: 0;
  min-height: 100%;
  position: fixed;
  top: 0;
  width: 100%;
  z-index: 1999;
}
.prv-page-loader-content[data-v-c98f6bfc] {
  position: absolute;
  left: 50%;
  top: 50%;
  width: 45rem;
  height: 45rem;
  margin: -25.625rem 0 0 -22.625rem;
}
.prv-page-loader-content img[data-v-c98f6bfc] {
  width: 100%;
  height: 100%;
}
.prv-page-loader-content[data-v-c98f6bfc]:hover {
  cursor: wait;
}
.prv-page-loader-content__linear[data-v-c98f6bfc] {
  overflow: hidden;
  width: 70%;
  height: 0.5rem;
  background-color: rgba(214,214,214,0.5);
  margin: 0 auto;
}
.prv-page-loader-content__indeterminate[data-v-c98f6bfc] {
  position: relative;
  width: 100%;
  height: 100%;
}
.prv-page-loader-content__indeterminate[data-v-c98f6bfc]:before {
  content: '';
  position: absolute;
  height: 100%;
  background-color: #ea8279;
  -webkit-animation: indeterminate_first-data-v-c98f6bfc 1.5s infinite ease-out;
          animation: indeterminate_first-data-v-c98f6bfc 1.5s infinite ease-out;
}
.prv-page-loader-content__indeterminate[data-v-c98f6bfc]:after {
  content: '';
  position: absolute;
  height: 100%;
  background-color: #f09088;
  -webkit-animation: indeterminate_second-data-v-c98f6bfc 1.5s infinite ease-in;
          animation: indeterminate_second-data-v-c98f6bfc 1.5s infinite ease-in;
}
@-webkit-keyframes indeterminate_first-data-v-c98f6bfc {
0% {
    left: -100%;
    width: 100%;
}
100% {
    left: 100%;
    width: 10%;
}
}
@keyframes indeterminate_first-data-v-c98f6bfc {
0% {
    left: -100%;
    width: 100%;
}
100% {
    left: 100%;
    width: 10%;
}
}
@-webkit-keyframes indeterminate_second-data-v-c98f6bfc {
0% {
    left: -150%;
    width: 100%;
}
100% {
    left: 100%;
    width: 10%;
}
}
@keyframes indeterminate_second-data-v-c98f6bfc {
0% {
    left: -150%;
    width: 100%;
}
100% {
    left: 100%;
    width: 10%;
}
}
