.ct-label {
  fill: rgba(0,0,0,0.4);
  color: rgba(0,0,0,0.4);
  font-size: 0.75rem;
  line-height: 1;
}
.ct-grid {
  stroke: rgba(0,0,0,0.2);
  stroke-width: 0.063rem;
  stroke-dasharray: 0.125rem;
}
.ct-point {
  stroke-width: 0.625rem;
  stroke-linecap: round;
}
.ct-line {
  fill: none;
  stroke-width: 0.25rem;
}
.ct-label.ct-vertical.ct-start {
  -webkit-box-align: end;
      -ms-flex-align: end;
          align-items: flex-end;
  -webkit-box-pack: end;
      -ms-flex-pack: end;
          justify-content: flex-end;
}
.ct-label.ct-horizontal.ct-end {
  -webkit-writing-mode: vertical-lr;
      -ms-writing-mode: tb-lr;
          writing-mode: vertical-lr;
  -webkit-transform: rotate(180deg);
          transform: rotate(180deg);
  height: auto !important;
  width: auto !important;
  margin-left: -5px;
  font-size: 0.7rem;
}
.ct-chart-line .ct-label {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
}
.ct-series-a .ct-slice-donut-solid {
  fill: #38e504;
}
.ct-series-b .ct-slice-donut-solid {
  fill: #ce6a88;
}
.ct-series-c .ct-slice-donut-solid {
  fill: #ede638;
}
.ct-chart-donut .ct-label {
  fill: #000;
  font-size: 1rem;
  font-family: Montserrat-Bold;
}
.ct-series-a .ct-point {
  stroke: #38e504;
  stroke-width: 0.4rem;
}
.ct-series-b .ct-point {
  stroke: #ea8279;
  stroke-width: 0.4rem;
}
.ct-series-c .ct-point {
  stroke: #377bdc;
  stroke-width: 0.4rem;
}
.ct-series-a .ct-line {
  stroke: #38e504;
  stroke-width: 0.15rem;
}
.ct-series-b .ct-line {
  stroke: #ea8279;
  stroke-width: 0.15rem;
}
.ct-series-c .ct-line {
  stroke: #377bdc;
  stroke-width: 0.15rem;
}
.chartist-tooltip {
  position: absolute;
  display: inline-block;
  opacity: 0;
  min-width: 5em;
  padding: 0.5em;
  background: #377bdc;
  border-radius: 0.2rem;
  font-weight: 700;
  color: #fff;
  text-align: center;
  pointer-events: none;
  z-index: 1;
  -webkit-transition: opacity 0.2s linear;
  transition: opacity 0.2s linear;
}
.chartist-tooltip:before {
  content: '';
  position: absolute;
  top: 100%;
  left: 50%;
  width: 0;
  height: 0;
  margin-left: -0.6375rem;
  border: 0.6375rem solid transparent;
  border-top-color: #377bdc;
}
.chartist-tooltip.tooltip-show {
  opacity: 1;
}
.ct-area,
.ct-line {
  pointer-events: none;
}
@font-face {
  font-family: Montserrat-ExtraLight;
  src: url("/assets/fonts/Montserrat-ExtraLight.ttf");
}
@font-face {
  font-family: Montserrat-ExtraLightItalic;
  src: url("/assets/fonts/Montserrat-ExtraLightItalic.ttf");
}
@font-face {
  font-family: Montserrat-Italic;
  src: url("/assets/fonts/Montserrat-Italic.ttf");
}
@font-face {
  font-family: Montserrat-Light;
  src: url("/assets/fonts/Montserrat-Light.ttf");
}
@font-face {
  font-family: Montserrat-LightItalic;
  src: url("/assets/fonts/Montserrat-LightItalic.ttf");
}
@font-face {
  font-family: Montserrat-Medium;
  src: url("/assets/fonts/Montserrat-Medium.ttf");
}
@font-face {
  font-family: Montserrat-MediumItalic;
  src: url("/assets/fonts/Montserrat-MediumItalic.ttf");
}
@font-face {
  font-family: Montserrat-Regular;
  src: url("/assets/fonts/Montserrat-Regular.ttf");
}
@font-face {
  font-family: Montserrat-Bold;
  src: url("/assets/fonts/Montserrat-Bold.ttf");
}
@font-face {
  font-family: Montserrat-SemiBold;
  src: url("/assets/fonts/Montserrat-SemiBold.ttf");
}
@font-face {
  font-family: Montserrat-SemiBoldItalic;
  src: url("/assets/fonts/Montserrat-SemiBoldItalic.ttf");
}
html {
  -webkit-box-sizing: border-box;
          box-sizing: border-box;
}
@media only screen and (min-width: 1681px) {
  html {
    font-size: 90%;
  }
}
@media only screen and (max-width: 1680px) {
  html {
    font-size: 85%;
  }
}
@media only screen and (max-width: 1600px) {
  html {
    font-size: 85%;
  }
}
@media only screen and (max-width: 1440px) {
  html {
    font-size: 80%;
  }
}
@media only screen and (max-width: 1366px) {
  html {
    font-size: 80%;
  }
}
@media only screen and (max-width: 1024px) {
  html {
    font-size: 85%;
  }
}
@media screen and (max-width: 1024px) and (max-height: 700px) {
  html {
    font-size: 75%;
  }
}
@media screen and (max-width: 1024px) and (max-height: 600px) {
  html {
    font-size: 65%;
  }
}
@media only screen and (max-width: 768px) {
  html {
    font-size: 56%;
  }
}
@media only screen and (max-width: 430px) {
  html {
    font-size: 48%;
  }
}
*,
*::before,
*::after {
  margin: 0;
  padding: 0;
  -webkit-box-sizing: inherit;
          box-sizing: inherit;
}
button:focus {
  outline: 0 !important;
}
button,
input,
select,
textarea {
  font-family: inherit;
  margin: 0;
}
input[type="radio"] + span {
  cursor: pointer;
}
body {
  font-family: Montserrat-Regular, sans-serif;
  color: #5a5959;
}
.home {
  font-size: 1rem;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
}
.home__side-bar {
  -webkit-box-flex: 0;
      -ms-flex: 0 0 7%;
          flex: 0 0 7%;
  min-height: 100vh;
  -webkit-box-shadow: 0 0.25rem 0.5rem 0 #d6d6d6;
          box-shadow: 0 0.25rem 0.5rem 0 #d6d6d6;
  background-image: -webkit-gradient(linear, left top, left bottom, from(#377bdc), to(#3052ac));
  background-image: linear-gradient(to bottom, #377bdc, #3052ac);
}
@media only screen and (max-width: 430px) {
  .home__side-bar {
    -webkit-box-flex: 0;
        -ms-flex: 0;
            flex: 0;
  }
}
.home__side-bar--menu {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
  -webkit-box-flex: 0;
      -ms-flex: 0 0 6%;
          flex: 0 0 6%;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  min-height: 100vh;
  overflow-y: scroll;
}
.home__side-bar--menu::-webkit-scrollbar {
  width: 12px;
}
.home__side-bar--menu::-webkit-scrollbar-track {
  background: #f1f1f1;
}
.home__side-bar--menu::-webkit-scrollbar-thumb {
  background: #888;
  border-radius: 6px;
}
.home__side-bar--menu::-webkit-scrollbar-thumb:hover {
  background: #555;
}
.home__hamburger {
  margin-top: 1.5rem;
  width: 100%;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  cursor: pointer;
}
.home__search {
  margin-top: 10rem;
  width: 100%;
  -webkit-box-flex: 0;
      -ms-flex: 0 0 3.625rem;
          flex: 0 0 3.625rem;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  align-items: center;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  border-left: 1.5rem solid #f09088;
  background-color: rgba(255,255,255,0.2);
}
.home__search__img {
  height: 1.625rem;
  width: 1.625rem;
  -webkit-transform: translateX(-39%);
          transform: translateX(-39%);
  cursor: pointer;
}
.home__icon {
  height: 2rem;
  margin-top: 5rem;
  width: 100%;
  -webkit-box-flex: 0;
      -ms-flex: 0 0 3.625rem;
          flex: 0 0 3.625rem;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
}
.home__icon__img {
  width: 2.2rem;
  height: 2.2rem;
  cursor: pointer;
}
@media only screen and (max-width: 1366px) {
  .home__icon__img {
    width: 2.2rem;
    height: 2.2rem;
  }
}
@media only screen and (max-width: 1024px) {
  .home__icon__img {
    width: 1.7rem;
    height: 1.7rem;
  }
}
.home__icon__img--sm {
  width: 1.8rem;
  height: 1.8rem;
  cursor: pointer;
}
.home__icon__img--md {
  width: 2rem;
  height: 2rem;
  cursor: pointer;
}
.home__icon__img--add {
  -webkit-transform: translateX(0.35rem);
          transform: translateX(0.35rem);
  width: 2.5rem;
  height: 2.5rem;
}
@media only screen and (max-width: 1366px) {
  .home__icon__img--add {
    width: 2.5rem;
    height: 2.5rem;
  }
}
@media only screen and (max-width: 1024px) {
  .home__icon__img--add {
    width: 2rem;
    height: 2rem;
  }
}
.home__icon__img--user {
  width: 1.65rem;
  height: 1.65rem;
}
.home__icon--active {
  border-left: 0.85rem solid #f09088;
  background-color: rgba(255,255,255,0.2);
  border-right: 0.85rem solid transparent;
}
.home__view {
  -webkit-box-flex: 1;
      -ms-flex: 1 0;
          flex: 1 0;
  display: inherit;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
}
.home__container {
  -webkit-box-flex: 1;
      -ms-flex: 1 0;
          flex: 1 0;
  background-color: #ededed;
  padding: 1rem;
}
@media only screen and (max-width: 430px) {
  .home__container {
    padding: 0.5rem 0;
    padding-top: 0;
  }
}
.home__container--gap {
  background-color: #fff;
  height: 100%;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
}
@media only screen and (max-width: 430px) {
  .home__container--gap {
    -webkit-box-shadow: none;
            box-shadow: none;
  }
}
